html {
  body {
    font-family: "Roboto", sans-serif;
    background-color: rgba(0, 0, 0, 0.09);
    .table-bordered td,
    .table-bordered th {
      border: 1px solid #999;
    }
    .MuiFilledInput-root {
      border-radius: 36px;
    }
    .MuiFilledInput-underline:before {
      border-bottom: none;
    }
    .MuiFilledInput-underline:after {
      display: none;
    }
    .MuiFilledInput-underline:hover:before {
      border-bottom: none;
    }
    .mail_to_modal {
      .table-bordered td,
      .table-bordered th {
        border: 1px solid #000;
      }
    }
  }
}
.menu-area {
  .navbar {
    box-shadow: none;
  }
}
.logo_text {
  display: block;
  text-align: left;
  width: 100%;
  padding-left: 8px;
  font-size: 16px;
}
.logo_img {
  height: 100px;
  margin-bottom: 20px;
}
.navbar-light .navbar-nav .nav-link:focus,
.navbar-light .navbar-nav .nav-link:hover {
  color: #007bff !important;
}
.navbar-light .navbar-nav .active > .nav-link,
.navbar-light .navbar-nav .nav-link.active,
.navbar-light .navbar-nav .nav-link.show,
.navbar-light .navbar-nav .show > .nav-link {
  color: #007bff !important;
}
.navbar {
  padding: 0 15px !important;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.15);
  .dropdown-menu {
    left: -86px;
  }
  .navbar-nav {
    .nav-link {
      color: #888;
      padding: 5px 15px !important;
      &:hover {
        color: #007bff;
      }
    }
  }

  .navbar-brand {
    span {
      font-size: 18px;
      color: #dc3545;
      letter-spacing: 2px;
      font-weight: bold;
      font-family: "Orbitron", sans-serif;
      strong {
        color: #000;
      }
    }
    img {
      height: 45px;
    }
  }
}
.sidenav_wrapper {
  a {
    text-decoration: inherit;
    color: #000;
  }
  a.active {
    color: #3f51b5;
    font-weight: 600;
    .MuiSvgIcon-root {
      fill: #3f51b5;
    }
  }
}

.auth_wrapper {
  border: 1px solid #ddd;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding-bottom: 50px;
}

.error {
  color: red;
}

main.main_wrapper {
  padding: 60px 0 50px;
}
.copy_body {
  table {
    thead {
      background: #fff;
    }
  }
}
.top_head_table {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  background: #fff;
  z-index: 1;
  left: 0;
  right: 0;
  -webkit-box-shadow: 1px 2px 5px 0px rgba(0, 0, 0, 0.1);
  box-shadow: 1px 2px 5px 0px rgba(0, 0, 0, 0.1);
  padding: 5px 15px;
  .select_wrap {
    display: flex;
    width: 30%;
    .MuiFormControl-root {
      margin: 0 15px 0 0;
      height: 38px;
    }
    .MuiFilledInput-input {
      padding: 15px 12px 3px;
      font-size: 13px;
    }
    .MuiInputLabel-formControl {
      top: -7px;
    }
  }
}
.table_body_wrap {
  table {
    th {
      text-align: center;
    }
    td {
      text-align: center;
      vertical-align: middle;
    }
  }
  .title_head {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  margin-top: 63px;
  position: relative;
  z-index: 0;
}
.MuiTableHead-root {
  tr {
    th {
      span {
        justify-content: center;
      }
    }
  }
}

.search_wrap {
  width: 40%;
  @media (max-width: 1350px) {
    width: 45%;
  }
  .MuiFormControl-root {
    margin: 0 15px 0 0;
  }
  .MuiFilledInput-input {
    padding: 15px 12px 3px;
    font-size: 13px;
  }
  .MuiInputLabel-formControl {
    top: -7px;
  }
  .search_box {
    min-width: 340px;
    .MuiIconButton-root {
      outline: none;
    }
    .MuiFilledInput-input {
      padding: 15px 12px 6px;
    }
    .MuiFormLabel-root {
      top: -10px;
    }
  }
  .select_column {
    min-width: 170px;
    .MuiSelect-filled.MuiSelect-filled {
      text-align: left;
    }
  }
}

.pagination_wrap {
  width: 30%;
  display: flex;
  justify-content: flex-end;
  .MuiPaginationItem-root {
    outline: none;
  }
  .MuiIconButton-root {
    padding: 0;
  }
  ul {
    list-style: none;
    display: flex;
    .no_next {
      .MuiIconButton-root {
        opacity: 0.5;
      }
    }
    li {
      display: none;
      &:first-child {
        display: inline-block;
      }
      &:last-child {
        display: inline-block;
      }
    }
  }
}

.client_mark {
  background-color: rgba(144, 238, 144, 0.06);
}
.conflict_mark {
  background-color: rgba(255, 0, 0, 0.03);
}

tr.head_th_title th {
  border: none;
  &:first-child {
    padding-left: 200px;
  }
}
.wordMark_table {
  .MuiIconButton-root[title="Search"] {
    display: none;
  }

  .MuiTablePagination-actions,
  .MuiTableFooter-root {
    display: none;
  }
  @media (min-width: 600px) {
    .MuiToolbar-regular {
      min-height: 110px;
      align-items: flex-start;
      padding: 10px 20px;
    }
  }
  .title_wrap_word_mark {
    position: absolute;
    display: flex;
    justify-content: space-between;
    bottom: 0;
    left: 18%;
    right: 18%;
    h5 {
      font-size: 18px;
    }
  }
  .MuiTableBody-root {
    .MuiTableCell-body {
      color: #000;
    }
    tr {
      td {
        background-color: rgba(255, 0, 0, 0.03);
        text-align: center;
        &:nth-child(1) {
          background-color: rgba(144, 238, 144, 0.06);
        }
        &:nth-child(2) {
          background-color: rgba(144, 238, 144, 0.06);
          width: 140px;
        }
        &:nth-child(3) {
          background-color: rgba(144, 238, 144, 0.06);

          width: 40px;
        }
        &:nth-child(4) {
          background-color: rgba(144, 238, 144, 0.06);
          font-weight: bold;
          border-right: 1px solid #ddd;
        }
        &:nth-child(5) {
          background-color: #fff;
          width: 85px;
        }
        &:nth-child(6) {
          font-weight: bold;
          border-left: 1px solid #ddd;
        }
        &:nth-child(7) {
          width: 40px;
        }
        &:nth-child(8) {
          width: 140px;
        }
      }
    }
  }
}
.logo_mark_table {
  th {
    text-align: center;
  }
  .MuiTableBody-root {
    tr {
      td {
        text-align: center;
        &:nth-child(2) {
          width: 220px;
        }
        &:nth-child(3) {
          width: 80px;
        }
        &:nth-child(4) {
          width: 180px;
        }
        &:nth-child(5) {
          width: 80px;
        }
        &:nth-child(6) {
          width: 180px;
        }
      }
    }
  }
}

.export_icon {
  color: rgba(0, 0, 0, 0.54);
  height: 20px;
  width: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  &:hover {
    color: #323edd;
  }
}

.logo_mark {
  height: 100px;
  width: 160px;
  object-fit: contain;
}

.update_wrap {
  display: flex;
  align-items: center;
  justify-content: center;
  .MuiButton-containedPrimary {
    margin-left: 10px;
  }
  .form-control {
    max-width: 230px;
  }
}

.edit_wrap {
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 800;
  .edit_mark {
    margin-left: 5px;
    cursor: pointer;
  }
}

@media print {
  @page {
    margin: 2.5cm;
  }
  .edit_mark,
  .update_wrap {
    display: none;
  }
  .wordMark_table {
    .MuiTableBody-root {
      tr {
        td {
          width: auto;
          text-align: center;
        }
      }
    }
  }
}
.profile_modal {
  @media (min-width: 576px) {
    .modal-dialog {
      max-width: 640px;
      margin: 1.75rem auto;
    }
  }
  .modal-dialog {
    .MuiFormControl-root {
      max-width: 170px;
    }
    .MuiButton-root {
      max-width: 170px;
      font-size: 13px;
    }
  }
}

@media (max-width: 767px) {
  .top_head_table .select_wrap {
    margin: 10px 0 0;
  }
  .wordMark_table {
    .title_wrap_word_mark {
      display: none;
    }
    .MuiTable-root {
      display: block;
    }
    .MuiTableBody-root {
      tr {
        td {
          background-color: #fff;
          text-align: center;
          &:nth-child(1) {
            background-color: #fff;
          }
          &:nth-child(2) {
            background-color: #fff;
            width: 200px;
          }
          &:nth-child(3) {
            background-color: #fff;
          }
          &:nth-child(4) {
            background-color: #fff;
            font-weight: bold;
            border-right: none;
          }
          &:nth-child(5) {
            background-color: #fff;
          }
          &:nth-child(6) {
            border-left: none;
          }
        }
      }
    }
  }
}

@keyframes ldio-hfjav9dlpac-o {
  0% {
    opacity: 1;
    transform: translate(0 0);
  }
  49.99% {
    opacity: 1;
    transform: translate(40px, 0);
  }
  50% {
    opacity: 0;
    transform: translate(40px, 0);
  }
  100% {
    opacity: 0;
    transform: translate(0, 0);
  }
}
@keyframes ldio-hfjav9dlpac {
  0% {
    transform: translate(0, 0);
  }
  50% {
    transform: translate(40px, 0);
  }
  100% {
    transform: translate(0, 0);
  }
}
.ldio-hfjav9dlpac div {
  position: absolute;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  top: 30px;
  left: 10px;
}
.ldio-hfjav9dlpac div:nth-child(1) {
  background: #e90c59;
  animation: ldio-hfjav9dlpac 0.8474576271186439s linear infinite;
  animation-delay: -0.42372881355932196s;
}
.ldio-hfjav9dlpac div:nth-child(2) {
  background: #007bff;
  animation: ldio-hfjav9dlpac 0.8474576271186439s linear infinite;
  animation-delay: 0s;
}
.ldio-hfjav9dlpac div:nth-child(3) {
  background: #e90c59;
  animation: ldio-hfjav9dlpac-o 0.8474576271186439s linear infinite;
  animation-delay: -0.42372881355932196s;
}
.loadingio-spinner-dual-ball-xv37mtfudqd {
  width: 64px;
  height: 64px;
  display: inline-block;
  overflow: hidden;
  background: none;
}
.ldio-hfjav9dlpac {
  width: 100%;
  height: 100%;
  position: relative;
  transform: translateZ(0) scale(0.64);
  backface-visibility: hidden;
  transform-origin: 0 0; /* see note above */
}
.ldio-hfjav9dlpac div {
  box-sizing: content-box;
}
.client_mark_modal {
  position: fixed;
  background: #fff;
  border: 1px solid #ddd;
  padding: 20px;
  bottom: 20px;
  height: 250px;
  box-shadow: 0 1px 6px 0 rgba(0, 0, 0, 0.3);
  border-radius: 8px;
  transition: all 0.3s ease-in-out;
  .close_modal {
    background: #ddd;
    border-radius: 50%;
    height: 30px;
    width: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    right: 15px;
    cursor: pointer;
  }
  .modal_body {
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: center;
    align-items: center;
    h4 {
      font-size: 20px;
    }
  }
}
.conflict_mark_modal {
  position: fixed;
  background: #fff;
  border: 1px solid #ddd;
  padding: 20px;
  bottom: 20px;
  right: 20px;
  height: 250px;
  box-shadow: 0 1px 6px 0 rgba(0, 0, 0, 0.3);
  border-radius: 8px;
  transition: all 0.3s ease-in-out;
  .close_modal {
    background: #ddd;
    border-radius: 50%;
    height: 30px;
    width: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    right: 15px;
    cursor: pointer;
  }
  .modal_body {
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: center;
    align-items: center;
    h4 {
      font-size: 20px;
    }
  }
}

.applicationTd {
  cursor: pointer;
}

.popover-header {
  display: flex;
  justify-content: space-between;
  background-color: #007bff !important;
  color: #fff;
  .close_popover {
    cursor: pointer;
  }
}
.popover-body {
  max-height: 200px;
  overflow-y: auto;
  background: #007bff29;
}
.bs-popover-right > .arrow::before {
  left: 0;
  border-width: 0.5rem 0.5rem 0.5rem 0;
  border-right-color: #007bff29;
}
.bs-popover-right > .arrow::after {
  left: 1px;
  border-width: 0.5rem 0.5rem 0.5rem 0;
  border-right-color: #007bff29;
}

.pdf_view_iframe {
  height: calc(100vh - 124px);
  margin-top: 45px;
}

.data_not_found {
  font-size: 20px;
  width: 100%;
  text-align: center;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.12);
  max-width: 300px;
  margin: 170px auto;
  height: 180px;
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.features {
  .fas {
    font-size: 35px;
    color: #007bff;
  }
}
.feature_tabs {
  .img-fluid {
    height: 320px;
    border-radius: 8px;
  }
}

.MuiSvgIcon-root.watched {
  color: #e90c59;
}
.MuiSvgIcon-root.not_watched {
  color: #777;
}

tr.watched_row {
  background: rgba(233, 12, 89, 0.025);
}

table {
  tr {
    td {
      .show > .btn-success.dropdown-toggle:focus {
        box-shadow: none;
      }
      .show > .btn-success.dropdown-toggle {
        color: #777;
        background-color: transparent;
        border-color: transparent;
      }
      .btn-success:not(:disabled):not(.disabled):active,
      .show > .btn-success.dropdown-toggle {
        color: #777;
        background-color: transparent;
        border-color: transparent;
      }
      .dropdown {
        .btn {
          background: transparent;
          border: 0;

          font-size: 25px;
          outline: none;
          &:focus {
            box-shadow: none;
          }
          .fa,
          .fas {
            font-size: 15px;
            color: #777;
          }
          .red {
            color: #c71616;
          }
          .green {
            color: green;
          }
        }
        .dropdown-menu.show {
          .dropdown-item {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 5px 15px;
            p {
              margin-bottom: 0;
              margin-right: 15px;
            }
            .fas {
              font-size: 16px;
            }
          }
        }
        .dropdown-item {
          .fa,
          .fas {
            font-size: 18px;
            color: #777;
          }
          .red {
            color: #c71616;
          }
          .green {
            color: green;
          }
        }
      }
    }
  }
}

.comparision_wrap {
  table {
    tbody {
      tr {
        td {
          &:first-child {
            .MuiCheckbox-root {
              float: left;
              padding: 0;
            }
            .dropdown {
              .btn {
                padding: 0;
                font-size: 10px;
              }
            }
          }
        }
      }
    }
  }
}

.dashboard_wrapper {
  padding: 15px 0;
}

.new_application_head {
  .search_wrap {
    width: 50%;
  }
}

.dashboard_wrapper {
  padding: 30px 0;
  .card {
    border: 0;
    box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.15);
    border-radius: 14px;
    .card-header {
      color: #fff;
      text-transform: uppercase;
      font-weight: 500;
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-top-left-radius: 14px;
      border-top-right-radius: 14px;
      height: 57px;
      background-image: linear-gradient(
        to right,
        #00c6ff 0%,
        #0072ff 51%,
        #00c6ff 100%
      );
      h5 {
        font-weight: 300;
      }
      .MuiFormControl-root {
        max-width: 165px;
      }
      .MuiInputLabel-formControl {
        color: #fff;
      }
      .MuiFilledInput-input {
        color: #fff;
      }
    }
    .card-body {
      padding: 15px;
      .table {
        margin-bottom: 0;
      }
    }
  }
}

.no_data_found {
  margin: 30px 0;
}

.modal-dialog {
  .modal-content {
    border-radius: 18px;
  }
}

.detail_modal {
  .modal-dialog {
    max-width: 570px;
  }
  .modal-header {
    .modal-title {
      width: 100%;
      text-align: center;
      font-weight: 300;
    }
  }
  .modal-body {
    h5 {
      margin: 10px 0 !important;
    }
    .comarision_table__t {
      tr {
        &:nth-child(3) {
          background-color: rgba(144, 238, 144, 0.06);
        }
        &:nth-child(4) {
          background-color: rgba(144, 238, 144, 0.06);
        }
        &:nth-child(5) {
          background-color: rgba(144, 238, 144, 0.06);
        }
        &:nth-child(6) {
          display: none;
        }
        &:nth-child(8) {
          background-color: rgba(255, 0, 0, 0.03);
        }
        &:nth-child(9) {
          background-color: rgba(255, 0, 0, 0.03);
        }
        &:nth-child(10) {
          background-color: rgba(255, 0, 0, 0.03);
        }
      }
    }
    table {
      tr {
        td {
          h4.score_text {
            text-align: left;
            margin-bottom: 0;
            font-size: 18px;
            font-weight: 300;
            strong {
              color: #007bff;
              font-weight: 500;
            }
          }
          padding-top: 0.2rem;
          img {
            height: 120px;
            object-fit: contain;
          }
          .dropdown .btn {
            padding: 0;
            font-size: 0;
            margin-left: 15px;
          }
          &:first-child {
            width: 135px;
          }
        }
      }
    }
  }
}

.dashboard_wrapper {
  table {
    tr {
      td {
        padding: 0.7rem;
      }
    }
  }
}

.publish_item {
  cursor: not-allowed;
  opacity: 0.5;
}
.sign-form {
  border-bottom-left-radius: 18px;
  border-bottom-right-radius: 18px;
}

.mail_to_modal {
  .modal-title {
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding-right: 15px;
  }
  .modal-lg,
  .modal-xl {
    max-width: 900px;
  }

  table {
    thead {
      th {
        font-size: 14px;
      }
    }
    tbody {
      tr {
        td {
          img {
            height: 120px;
            width: 140px;
            object-fit: contain;
          }
        }
      }
    }
  }
}

.MuiDrawer-paper {
  width: 250px;

  justify-content: center;
}

.keywords_modal {
  .modal-body {
    padding: 30px;
  }
  .multiple-value-text-input input {
    height: 45px;
    padding: 10px;
  }
}

.setting_wrapper {
  padding: 0 0;
  ul {
    list-style: none;
    margin: 0;
    padding: 0;
    li {
      margin-bottom: 15px;
      button {
        min-width: 200px;
        height: 55px;
      }
    }
  }
}

.MuiChip-outlined.MuiChip-root {
  background-color: #fff;
}

.suggetion {
  h5 {
    margin-bottom: 25px;
    text-transform: uppercase;
  }
  .head {
    margin-bottom: 20px;
    .form-group {
      display: flex;
      align-items: center;
      .MuiButton-root {
        margin: 0;
        width: auto;
        margin-left: 10px;
      }
    }
  }
  ul {
    li {
      background: #007bff87;
      max-width: 265px;
      /* height: 30px; */
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 8px 15px;
      border-radius: 6px;
      cursor: pointer;
      color: #000;
      text-transform: capitalize;
    }
  }
  .suggestion_ul {
    li {
      background-color: #323edd;
      color: #fff;
    }
  }
  .MuiButton-root {
    height: 45px;
    width: 265px;
    margin-top: 30px;
  }
}

.setting_wrapper {
  section {
    border-bottom: 2px solid #ddd;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.15);
    margin: 30px 0;
    border-radius: 14px;
    padding: 40px 25px;
    background: #fff;
    &:last-child {
      border-bottom: 0;
    }

    .title {
      font-size: 25px;
      text-transform: uppercase;
    }
  }
}

.new_application {
  table {
    tr {
      th {
        text-align: center;
      }
      td {
        vertical-align: middle;
        text-align: center;
        font-size: 18px;
      }
    }
  }
}

.table {
  background-color: #fff;
  thead {
    background-color: #f8f9fa;
  }
}

.navbar {
  .nav-link {
    p {
      margin-bottom: 0;
      text-align: center;
    }
  }
}

.isNoticePage {
  .main_wrapper {
    padding-bottom: 0;
  }
}
